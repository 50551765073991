import * as i0 from '@angular/core';
import { PLATFORM_ID, Injectable, Inject, Directive, Input, NgModule } from '@angular/core';
import { Subject, of, fromEvent } from 'rxjs';
import { takeUntil, filter, map, mergeMap, debounceTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
class BrowserWindowRef {
  platformId;
  constructor(platformId) {
    this.platformId = platformId;
  }
  get nativeWindow() {
    if (isPlatformBrowser(this.platformId)) {
      return this.windowRef();
    }
    return false;
  }
  windowRef() {
    // return the global native browser window object
    return window;
  }
  static ɵfac = function BrowserWindowRef_Factory(t) {
    return new (t || BrowserWindowRef)(i0.ɵɵinject(PLATFORM_ID));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BrowserWindowRef,
    factory: BrowserWindowRef.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrowserWindowRef, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
class ParallaxDirective {
  hostElement;
  renderer;
  wr;
  speed = 30;
  axe = 'y';
  property = 'transform';
  propertyValue = 'translate3d';
  active = true;
  inViewport = true;
  observer;
  element;
  initialPosition;
  componentDestroy$ = new Subject();
  windowScroll$;
  windowResize$;
  constructor(hostElement, renderer, wr) {
    this.hostElement = hostElement;
    this.renderer = renderer;
    this.wr = wr;
  }
  ngAfterViewInit() {
    this.element = this.hostElement.nativeElement;
    if (this.element && this.wr.nativeWindow && !this.observer) {
      this.initParallax();
    }
  }
  initParallax() {
    this.observer = this.createObserver(0);
    this.observer.observe(this.element);
    const parallax$ = of('').pipe(takeUntil(this.componentDestroy$), filter(() => this.active && this.inViewport), map(() => {
      const coef = this.calculateCoef();
      this.renderParallax(coef);
      return coef;
    }));
    this.windowScroll$ = fromEvent(this.wr.nativeWindow, 'scroll').pipe(mergeMap(() => parallax$));
    this.windowResize$ = fromEvent(this.wr.nativeWindow, 'resize').pipe(debounceTime(500), mergeMap(() => parallax$));
  }
  startParallax() {
    this.inViewport = true;
    if (this.wr.nativeWindow && this.initialPosition === undefined) {
      this.initialPosition = this.wr.nativeWindow.pageYOffset;
      this.windowScroll$.subscribe();
      this.windowResize$.subscribe();
    }
  }
  destroyParallax() {
    this.inViewport = false;
  }
  calculateCoef() {
    return (this.wr.nativeWindow.pageYOffset - this.initialPosition) * (this.speed / 100);
  }
  renderParallax(coef) {
    this.renderer.setStyle(this.element, this.property, this.getPropertyValue(coef));
  }
  getPropertyValue(coef) {
    let result = `${coef}px`;
    if (this.property === 'transform') {
      switch (this.propertyValue) {
        case 'translate3d':
          result = `translate3d(${this.getAxe(coef)})`;
          break;
        case 'scale':
          result = `scale(${coef})`;
          break;
        case 'rotate':
          result = `rotate(${coef}deg)`;
          break;
      }
    }
    switch (this.property) {
      case 'opacity':
        result = `${coef}`;
        break;
    }
    return result;
  }
  getAxe(coef) {
    let axe = [0, coef, 0];
    if (this.axe === 'x') {
      axe = [coef, 0, 0];
    } else if (this.axe === 'z') {
      axe = [0, 0, coef];
    }
    return `${axe[0]}px, ${axe[1]}px, ${axe[2]}px`;
  }
  createObserver(pourcent) {
    const options = {
      rootMargin: '0px',
      threshold: pourcent / 100
    };
    const isIntersecting = entry => entry.isIntersecting || entry.intersectionRatio > 0;
    return new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.startParallax();
        } else {
          this.destroyParallax();
        }
      });
    }, options);
  }
  ngOnDestroy() {
    this.componentDestroy$.next(false);
    this.componentDestroy$.complete();
    if (this.wr.nativeWindow) {
      this.observer.disconnect();
    }
  }
  static ɵfac = function ParallaxDirective_Factory(t) {
    return new (t || ParallaxDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(BrowserWindowRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ParallaxDirective,
    selectors: [["", "ngx-parallax", ""]],
    inputs: {
      speed: "speed",
      axe: "axe",
      property: "property",
      propertyValue: "propertyValue",
      active: "active"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ParallaxDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-parallax]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: BrowserWindowRef
  }], {
    speed: [{
      type: Input
    }],
    axe: [{
      type: Input
    }],
    property: [{
      type: Input
    }],
    propertyValue: [{
      type: Input
    }],
    active: [{
      type: Input
    }]
  });
})();
class NgxParallaxModule {
  static ɵfac = function NgxParallaxModule_Factory(t) {
    return new (t || NgxParallaxModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxParallaxModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxParallaxModule, [{
    type: NgModule,
    args: [{
      declarations: [ParallaxDirective],
      imports: [],
      exports: [ParallaxDirective]
    }]
  }], null, null);
})();
class ParallaxStandaloneDirective {
  hostElement;
  renderer;
  wr;
  speed = 30;
  axe = 'y';
  property = 'transform';
  propertyValue = 'translate3d';
  active = true;
  inViewport = true;
  observer;
  element;
  initialPosition;
  componentDestroy$ = new Subject();
  windowScroll$;
  windowResize$;
  constructor(hostElement, renderer, wr) {
    this.hostElement = hostElement;
    this.renderer = renderer;
    this.wr = wr;
  }
  ngAfterViewInit() {
    this.element = this.hostElement.nativeElement;
    if (this.element && this.wr.nativeWindow && !this.observer) {
      this.initParallax();
    }
  }
  initParallax() {
    this.observer = this.createObserver(0);
    this.observer.observe(this.element);
    const parallax$ = of('').pipe(takeUntil(this.componentDestroy$), filter(() => this.active && this.inViewport), map(() => {
      const coef = this.calculateCoef();
      this.renderParallax(coef);
      return coef;
    }));
    this.windowScroll$ = fromEvent(this.wr.nativeWindow, 'scroll').pipe(mergeMap(() => parallax$));
    this.windowResize$ = fromEvent(this.wr.nativeWindow, 'resize').pipe(debounceTime(500), mergeMap(() => parallax$));
  }
  startParallax() {
    this.inViewport = true;
    if (this.wr.nativeWindow && this.initialPosition === undefined) {
      this.initialPosition = this.wr.nativeWindow.pageYOffset;
      this.windowScroll$.subscribe();
      this.windowResize$.subscribe();
    }
  }
  destroyParallax() {
    this.inViewport = false;
  }
  calculateCoef() {
    return (this.wr.nativeWindow.pageYOffset - this.initialPosition) * (this.speed / 100);
  }
  renderParallax(coef) {
    this.renderer.setStyle(this.element, this.property, this.getPropertyValue(coef));
  }
  getPropertyValue(coef) {
    let result = `${coef}px`;
    if (this.property === 'transform') {
      switch (this.propertyValue) {
        case 'translate3d':
          result = `translate3d(${this.getAxe(coef)})`;
          break;
        case 'scale':
          result = `scale(${coef})`;
          break;
        case 'rotate':
          result = `rotate(${coef}deg)`;
          break;
      }
    }
    switch (this.property) {
      case 'opacity':
        result = `${coef}`;
        break;
    }
    return result;
  }
  getAxe(coef) {
    let axe = [0, coef, 0];
    if (this.axe === 'x') {
      axe = [coef, 0, 0];
    } else if (this.axe === 'z') {
      axe = [0, 0, coef];
    }
    return `${axe[0]}px, ${axe[1]}px, ${axe[2]}px`;
  }
  createObserver(pourcent) {
    const options = {
      rootMargin: '0px',
      threshold: pourcent / 100
    };
    const isIntersecting = entry => entry.isIntersecting || entry.intersectionRatio > 0;
    return new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.startParallax();
        } else {
          this.destroyParallax();
        }
      });
    }, options);
  }
  ngOnDestroy() {
    this.componentDestroy$.next(false);
    this.componentDestroy$.complete();
    if (this.wr.nativeWindow) {
      this.observer.disconnect();
    }
  }
  static ɵfac = function ParallaxStandaloneDirective_Factory(t) {
    return new (t || ParallaxStandaloneDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(BrowserWindowRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ParallaxStandaloneDirective,
    selectors: [["", "ngx-parallax", ""]],
    inputs: {
      speed: "speed",
      axe: "axe",
      property: "property",
      propertyValue: "propertyValue",
      active: "active"
    },
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ParallaxStandaloneDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-parallax]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: BrowserWindowRef
  }], {
    speed: [{
      type: Input
    }],
    axe: [{
      type: Input
    }],
    property: [{
      type: Input
    }],
    propertyValue: [{
      type: Input
    }],
    active: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of ngx-parallax
 */
// ngModule

/**
 * Generated bundle index. Do not edit.
 */

export { NgxParallaxModule, ParallaxDirective, ParallaxStandaloneDirective };
