<section class="parallax-bg fixed-bg lg-screen lg-section"
  style="background-image: url('assets/images/slides/home-bg-7.jpg')">
  <div class="hero-text-wrap lg-screen transparent-bg">
    <div class="hero-text">
      <div class="container">
        <div class="row">
          <div class="col-md-12 centerize-col">
            <div class="all-padding-50 text-center white-color content">
              <h1>Becki Agency</h1>
              <h2 class="font-100 raleway-font">The Perfect One Page Template</h2>
              <h4 class="font-300">Based on Bootstrap 5x Angular 17 and <br>One page Parallax Template</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="grey-bg pb-80">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font">Choose the template you like</h1>
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div *ngFor="let choose of chooserTemplate" class="col-md-4 col-sm-4 col-xs-12">
        <div class="product">
          <div class="product-wrap border-white-15">
            <img src="assets/images/demo-imgs/{{choose.img}}" class="img-responsive" alt="demo1" />
            <div class="product-caption">
              <div class="product-description text-center">
                <div class="product-description-wrap">
                  <div class="product-title"> <a [routerLink]="choose.url" [ngClass]="choose.btnClass"
                      target="_blank">{{choose.btnText}}</a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-detail text-left preview-box">
            <h3 class="raleway-font">{{choose.templateText}} <span class="gradient-color">{{choose.templateType}}</span>
            </h3>
            <h4>Key Features</h4>
            <ul class="list-style-02 mt-20">
              <li *ngFor="let key of choose.keyFeatures">
                {{key}}
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>

<div class="white-bg pt-100 pb-100">
  <div class="container">
    <div class="row">
      <div class="col-md-7 mt-20 mb-80">
        <img class="img-responsive" src="assets/images/responsive-screen-2.png" alt="responsive" />
      </div>
      <div class="col-md-5 mt-80 chart-style-01">
        <div class="row">
          <div class="col-md-6 mb-30 text-center">
            <circle-progress [percent]="99" [radius]="100" [outerStrokeWidth]="4" [outerStrokeColor]="'#212121'" [outerStrokeLinecap]="'butt'" [showInnerStroke]="false" [titleColor]="'#212121'" [subtitle]="'RESPONSIVE'" [subtitleFontSize]="'18'" [subtitleColor]="'#212121'" [animation]="true" [animationDuration]="800"></circle-progress>
          </div>
          <div class="col-md-6 mb-30 text-center">
            <circle-progress [percent]="85" [radius]="100" [outerStrokeWidth]="4" [outerStrokeColor]="'#212121'" [outerStrokeLinecap]="'butt'" [showInnerStroke]="false" [titleColor]="'#212121'" [subtitle]="'DESIGN'" [subtitleFontSize]="'18'" [subtitleColor]="'#212121'" [animation]="true" [animationDuration]="800"></circle-progress>
          </div>

          <div class="col-md-6 mb-30 text-center">
            <circle-progress [percent]="70" [radius]="100" [outerStrokeWidth]="4" [outerStrokeColor]="'#212121'" [outerStrokeLinecap]="'butt'" [showInnerStroke]="false" [titleColor]="'#212121'" [subtitle]="'BOOTSTRAP'" [subtitleFontSize]="'18'" [subtitleColor]="'#212121'" [animation]="true" [animationDuration]="800"></circle-progress>
          </div>
          <div class="col-md-6 mb-30 text-center">
            <circle-progress [percent]="90" [radius]="100" [outerStrokeWidth]="4" [outerStrokeColor]="'#212121'" [outerStrokeLinecap]="'butt'" [showInnerStroke]="false" [titleColor]="'#212121'" [subtitle]="'USER FRIENDLY'" [subtitleFontSize]="'18'" [subtitleColor]="'#212121'" [animation]="true" [animationDuration]="800"></circle-progress>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<section class="grey-bg pb-80">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 centerize-col text-center">
        <div class="section-title">
          <h1 class="raleway-font">Additional Pages</h1>
        </div>
      </div>
    </div>
    <div class="row mt-50">
      <div *ngFor="let inner of innerPages" class="col-md-4 col-sm-4 col-xs-12">
        <div class="product">
          <div class="product-wrap border-white-15">
            <img src="assets/images/demo-imgs/{{inner.img}}" class="img-responsive" alt="inner-demo" />
            <div class="product-caption">
              <div class="product-description text-center">
                <div class="product-description-wrap">
                  <div class="product-title"> <a [routerLink]="inner.url" class="btn btn-color btn-lg btn-square"
                      target="_blank">Live Preview</a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<section class="parallax-bg fixed-bg" style="background:url('assets/images/background/parallax-bg-2.jpg')">
  <div class="gradient-overlay-bg-two"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center parallax-content height-400px">
        <div class="center-layout">
          <div class="v-align-middle">
            <h1 class="font-400 white-color raleway-font xs-font-40px">We Make Themes That Solve Problems. Sometimes We
              Tell Stories.</h1>
            <p class="mt-50">
              <a href="javascript:void(0);" class="btn btn-dark btn-lg btn-square">Purchase Now</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>