<nav class="navbar navbar-expand-lg nav-crev py-0" [class.nav-scroll]="isSticky">
    <div class="container">
        <a class="navbar-brand icon-img-100" (click)="scrollToHome()">
            <img [src]="logo" alt="Logo" class="logo" >
        </a>
  
        <div class="dropdown language-margin ">
            <button class="btn btn-light-outline dropdown-toggle fs-10" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                 <span *ngIf="translate.currentLang == 'fr'"><img src="assets/images/icons/fr.png" width="18px" class="mr-1" > Fr</span>
                 <span *ngIf="translate.currentLang == 'en'"><img src="assets/images/icons/uk.png" width="18px" class="mr-1" > En</span>
                 <span *ngIf="translate.currentLang == 'ne'"><img src="assets/images/icons/ne.png" width="18px" class="mr-1" > Ne</span>
                 <span *ngIf="translate.currentLang == 'es'"><img src="assets/images/icons/es.png" width="18px" class="mr-1" > Es</span>
                 <span *ngIf="translate.currentLang == 'it'"><img src="assets/images/icons/it.png" width="18px" class="mr-1" > It</span>
            </button>
            <ul class="dropdown-menu fs-12">
                <li><a class="dropdown-item cursor-pointer" (click)="setLang('fr')">
                    <img src="assets/images/icons/fr.png" width="18px" class="mr-2"> Français</a>
                </li>
              <li><a class="dropdown-item cursor-pointer " (click)="setLang('en')">
                <img src="assets/images/icons/uk.png" width="18px" class="mr-2"> English</a>
            </li>
              <li><a class="dropdown-item cursor-pointer " (click)="setLang('ne')">
                <img src="assets/images/icons/ne.png" width="18px" class="mr-2"> Nederlands</a>
            </li>
              <li><a class="dropdown-item cursor-pointer" (click)="setLang('es')">
                <img src="assets/images/icons/es.png" width="18px" class="mr-2"> Espanol</a>
            </li>
              <li><a class="dropdown-item cursor-pointer" (click)="setLang('it')">
                <img src="assets/images/icons/it.png" width="18px" class="mr-2"> Italiano</a>
            </li>
            </ul>
        </div>

        <button class="navbar-toggler border-icon-bar ms-auto" type="button" id="closeNav" (click)="isCollapsed = !isCollapsed" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar" *ngIf="isCollapsed"><i class="icofont icofont-navigation-menu"></i></span>
            <span class="icon-bar" *ngIf="!isCollapsed"><i class="fa fa-times" aria-hidden="true"></i> </span>
        </button> 

        <div  class="collapse navbar-collapse" toggle="collapse" data-target=".navbar-collapse" [ngClass]="{'collapse': isCollapsed, 'navbar-collapse': true}" id="navbarNavAltMarkup">
           
             <ul class="navbar-nav navbar-center xs-mt-20 ml-big-left">
                
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('about')">{{'about' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('ourApp')">{{'our-solution' | translate}}</a>
                </li>
               
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('pricings')">{{'pricings' | translate}}</a>
                </li>
               
                <div class="try-btn-margin">
                    <button type="button" class="btn btn-lg btn-gradient-primary btn-circle " (click)="scrollToSection('contact')">{{'try-it-free' | translate}}</button>
                </div>
                 
            </ul>  
            
        </div>
        
    </div>
</nav>

 