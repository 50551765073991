<app-navbar></app-navbar>
<section class="parallax-bg fixed-bg xxl-screen pt-0 pb-0" style="background-image: url('assets/images/slides/home-bg-19.jpg')" id="home">
    <div class="hero-text-wrap xxl-screen">
        <div class="hero-text">
            <div class="text-content-slider">
                <owl-carousel-o [options]="customOptions" class="owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStore">
                        <div class="container">
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-8">
                                    <div class="all-padding-50 text-center white-color">
                                        <h2 class="font-100 raleway-font">{{slide.title}}</h2>
                                        <h1>{{slide.introtext}}</h1>
                                        <h2 class="font-100 raleway-font">{{slide.subtitle}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="white-bg xs-pb-100" id="features">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of features;" class="col-md-4 col-sm-4 col-xs-12 mb-30 hover-effect">
                <div [ngClass]="item.featureBox">
                    <i [ngClass]="item.icon"></i>
                    <h4 class="mt-0 font-600">{{item.title}}</h4>
                    <p class="font-400">{{item.desc}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen">
                    <img src="assets/images/about-heading-1.jpg" class="img-responsive border-radius-25" alt="about-heading-1" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12 text-left d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mt-50">
                        <h2 class="raleway-font mt-0">Becki is creative one page template</h2>
                        <h1 class="raleway-font orange-color mt-30 font-50px font-300">Our Awesome Features</h1>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                        Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                        Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
                    <p>Nulla luctus, tellus sed aliquam varius, mauris ligula lobortis lacus, condimentum aliquam odio felis
                        ullamcorper leo.</p>
                    <p class="mt-30 mb-0"><a class="btn btn-lg btn-gradient-six">Read more</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="bordered-bg white-bg lg-section xs-pb-100 xs-pt-100">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-right">
        <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/bg-right-img.jpg);"></div>
    </div>
    <div class="container">
        <div class="col-md-5 col-sm-6">
            <div class="section-title mb-50">
                <h2 class="raleway-font mt-0 font-20px">What We Do</h2>
                <h1 class="raleway-font orange-color mt-0 font-50px font-300">We Are Digital</h1>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper. Mauris
                at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum. Phasellus tempus
                tellus vitae ullamcorper hendrerit.</p>
            <p class="mt-30 mb-0"><a class="btn btn-lg btn-gradient-six">Read more</a></p>
        </div>
    </div>
</section>

<section class="grey-bg" id="team">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font">Meet Ninjas</h2>
                    <h1 class="raleway-font orange-color">Our Creative Team</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-30" *ngFor="let slide of slideStoreTwo">
                <div class="team-member">
                    <div class="team-thumb">
                        <div class="thumb-overlay"></div>
                        <img src="assets/images/team/{{slide.image}}" alt="">
                        <div [ngClass]="slide.index === 0 ? 'member-info text-center gradient-bg-nine' : 'member-info text-center gradient-bg-ten'">
                            <h3>{{slide.subtitle}}</h3>
                            <span class="title">{{slide.title}}</span>
                            <ul class="social-link list-inline">
                                <li *ngFor="let social of socialLinks">
                                    <a href="#" [ngClass]="social.iconOuter"><i [ngClass]="social.iconInner"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg pb-80" id="services">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font">What We Offer</h2>
                    <h1 class="raleway-font orange-color">Our Services</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>

        <div class="row mt-50">
            <div *ngFor="let service of services" class="col-md-4 col-sm-4 col-xs-12 mb-30 feature-box text-center hover-effect">
                <div class="gradient-bg-icon-six mb-20">
                    <i [ngClass]="service.icon"></i>
                </div>
                <h5 class="mt-10">{{service.title}}</h5>
                <p class="font-400">{{service.desc}}</p>
            </div>
        </div>

    </div>
</section>

<section class="gray-bg pb-100" id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-8 centerize-col text-center">
          <div class="section-title">
            <h2 class="raleway-font">We Are Becki</h2>
            <h1 class="raleway-font orange-color">App Features</h1>
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper. Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum. Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
        </div>
      </div>
      <div class="row mt-50">
        <div class="col-md-4 d-flex align-items-center">
            <div class="d-block">
                <div *ngFor="let service of leftServices" class="text-end mt-50 sm-text-center xs-text-center">
                    <h5 class="mt-0 font-600">{{service.title}}</h5>
                    <p class="font-300">{{service.desc}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 xs-mt-80 sm-mt-80">
   	    	<img class="img-responsive" src="assets/images/iphone-4.png" alt=""/>
   	    </div>
        <div class="col-md-4 d-flex align-items-center">
        	<div class="d-block">
                <div *ngFor="let service of rightServices" class="text-start mt-50 sm-text-center xs-text-center">
                    <h5 class="mt-0 font-600">{{service.title}}</h5>
                    <p class="font-300">{{service.desc}}</p>
                </div>
            </div>
        </div>
      </div>
    </div>
</section>

<section class="grey-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font">Show Your Project Gallery</h2>
                    <h1 class="raleway-font orange-color">Our Awesome Portfolio</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div *ngFor="let portfolio of portfolios" class="col-md-4 col-sm-6 portfolio with-spacing">
                <div class="portfolio-item border-white-15">
                    <a href="javascript:void(0)">
                        <img src="assets/images/portfolio/grid/{{portfolio.img}}" alt="">
                        <div class="portfolio-info gradient-bg-nine">
                            <div class="centrize">
                                <div class="v-center white-color">
                                    <h3>{{portfolio.title}}</h3>
                                    <p>{{portfolio.subTitle}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background-image: url('assets/images/background/parallax-bg-2.jpg')">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="font-400 white-color raleway-font xs-font-40px">We Make Themes That Solve Problems.
                            Sometimes We Tell Stories.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="grey-bg" id="pricings">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title mb-50">
                    <h2 class="raleway-font">Select Your Plan</h2>
                    <h1 class="raleway-font orange-color">Our Pricing Table</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div *ngFor="let price of pricing; let i = index" class="col-md-6 pricing-table col-sm-6 xs-mb-30 hover-effect">
                <div
                    class="pricing-box border-radius-25 {{ i === 1 ? 'pricing-box-gradient-nine' : 'pricing-box-gradient-eight' }} text-center white-color">
                    <h3 class="mb-0 raleway-font">{{price.title}}</h3>
                    <h4>{{price.subTitle}}</h4>
                    <h2 class="font-60px sm-font-40px"><sup>{{price.currency}}</sup><span>{{price.amount}}</span></h2>
                    <ul class="list-style-02">
                        <li *ngFor="let feature of price.features">{{ feature }}</li>
                    </ul>
                    <div class="pricing-box-bottom"> <a [ngClass]="price.btnClass">{{price.btnText}}</a> </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg pb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title mb-50">
                    <h2 class="raleway-font">What Our Client Says</h2>
                    <h1 class="raleway-font orange-color">Our Testimonials</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <owl-carousel-o [options]="customOptionsThree" class="testimonial gradient-bullet-five owl-btn-center-lr">
                    <ng-template carouselSlide *ngFor="let slide of slideStoreThree">
                        <div class="testimonial-item">
                            <div class="testimonial-content border-radius-25">
                                <img class="img-responsive rounded-circle text-center" src="{{slide.image}}" alt="" />
                                <h4 class="font-600 mb-0 raleway-font">{{slide.title}}</h4>
                                <span class="orange-color font-14px">{{slide.subTitle}}</span>
                                <div class="clearfix mb-20"></div>
                                <i class="icofont icofont-quote-left font-50px orange-color mt-20"></i>
                                <p class="mt-20 line-height-26 font-14px">{{slide.description}}</p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<div class="white-bg pt-120 pb-90">
    <div class="container">
      <div class="row">
        <div *ngFor="let logo of logos" class="col-md-4 col-sm-6 col-xs-12">
          <div class="white-bg text-center display-table mb-30 full-width client-logo-height">
              <div class="v-align-middle">
                <a href="javascript:void(0);"><img src="assets/images/clients/{{logo.img}}" alt="01"/></a>
              </div>
          </div>
        </div>
      </div>
    </div>
</div>

<section class="gradient-bg-ten xs-pb-60" id="blog">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-right">
      <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/blog-img.jpg);"></div>
    </div>
    <div class="container-fluid">
      <div class="col-md-6 col-sm-6">
        <div class="col-inner spacer white-color text-center">
          <h2 class="raleway-font mt-0 font-20px xs-font-17px">Read Our News</h2>
          <h1 class="raleway-font mt-0 font-50px font-300 xs-font-27px">Latest Blog Post</h1>
          <p>Objectively innovate empowered manufactured products whereas parallel platforms.<br> Holisticly predominate extensible testing procedures for reliable supply chains. </p>
          <p><a routerLink="/blog-details" class="btn btn-md btn-square btn-light-outline mt-30">See Blog Details</a></p>
        </div>
      </div>
    </div>
</section>

<section class="white-bg transition-none" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font">Just Keep In Touch</h2>
                    <h1 class="raleway-font orange-color">Contact Us Now</h1>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ex ac velit varius semper.
                    Mauris at dolor nec ante ultricies aliquam ac vitae diam. Quisque sodales vehicula elementum.
                    Phasellus tempus tellus vitae ullamcorper hendrerit.</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-70">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font orange-color">Postal Address</h2>
                            <p class="mt-30">PO Box 16122 Toronto Eaton Centre, 220 The PATH Toronto, ON M5B 2H1, Canada
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font orange-color">Office Numbers</h2>
                            <p class="mb-0 mt-30">Landline : +44 1234 567 9</p>
                            <p class="mb-0">Mobile : +44 1234 567 9</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font orange-color">Our Email</h2>
                            <p class="mb-0 mt-30">Order : order&#64;yourwebsite.com</p>
                            <p class="mb-0">Request : request&#64;yourwebsite.com</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font orange-color">Fast Support</h2>
                            <p class="mb-0 mt-30">Support : support&#64;yourwebsite.com</p>
                            <p class="mb-0">Career : career&#64;yourwebsite.com</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12 social-icons-style-06">
                        <ul class="md-icon left-icon">
                          <li *ngFor="let social of socialMedia">
                            <a [ngClass]="social.iconClass" href="javascript:void(0)"><i [ngClass]="social.icon"></i></a>
                          </li>
                        </ul>
                      </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <form (submit)="sendEmail($event)" class="contact-form-style-01" #contactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_name">Name</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userName.invalid && userName.touched" name="user_name"
                                    class="md-input" id="name" placeholder="Name *" [(ngModel)]="formData.user_name"
                                    #userName="ngModel" required>
                                <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_email">Email</label>
                                <input type="email" minlength="2" maxlength="35"
                                    [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                                    class="md-input" id="email" placeholder="Email *" [(ngModel)]="formData.user_email"
                                    #userEmail="ngModel" required email>
                                <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                                    A valid email is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                                <label class="visually-hidden" for="message">Project Details</label>
                                <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                                    class="md-textarea" id="message" rows="7" placeholder="Project Details"
                                    [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                                <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                                    Project details are required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="text-left mt-20">
                                <button type="submit" name="submit"
                                    class="btn btn-outline btn-md btn-circle btn-animate remove-margin"
                                    [disabled]="contactForm.invalid" value="Send">
                                    <span>Send Message <i class="ion-android-arrow-forward"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>